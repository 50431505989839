import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where
} from "firebase/firestore";
import { db } from "./../../firebaseConfig";
import "../../App.css";
import TalentCalendar from "../TalentCalendar";
import edit from "./../../images/edit.svg";
import expanded from "./../../images/expand.png";
import calendarIcon from "./../../images/calendar.svg";
import closeButton from "./../../images/close.svg";
import Select, { components } from "react-select";
import { ta } from "date-fns/locale";

// Define the TruncatedContent component
const TruncatedContent = ({ content, type }) => {
  if (!content) return null;

  let displayContent;
  if (type === "notes") {
    displayContent = content;
  } else {
    displayContent = Array.isArray(content)
      ? content.map(c => c.label).join(", ")
      : content;
  }

  return (
    <span>
      {displayContent.length > 40 ? `${displayContent.substring(0, 40)}...` : displayContent}
    </span>
  );
};

function TalentList() {
  const [search, setSearch] = useState(""); // New state for search term
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [talents, setTalents] = useState([]);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentFunction, setCurrentFunction] = useState("");
  const [currentSurname, setCurrentSurname] = useState("");
  const [currentPhone, setCurrentPhone] = useState("");
  const [currentOtherFunctions, setCurrentOtherFunctions] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [companyNames, setCompanyNames] = useState([]);
  const [locations, setLocations] = useState([]);
  const [bereich, setBereich]  = useState("");
  const [selectedGoodCompanies, setSelectedGoodCompanies] = useState([]);
  const [selectedBadCompanies, setSelectedBadCompanies] = useState([]);
  const [selectedtryoutCompanies, setSelectedtryoutCompanies] = useState([]);
  const [selectLocations, setSelectedLocations] = useState([]);
  const [selectedPosition, setselectedPosition] = React.useState(null);
  const [filterGoodCompanies, setfilterGoodCompanies] = useState([]);
  const [filterBadCompanies, setfilterBadCompanies] = useState([]);
  const [filtertryoutCompanies, setfilterTyoutCompanies] = useState([]);
  const [filterLocation, setfilterloation] = useState([]);
  const [notesProductLeads, setNotesProductLeads] = useState('');
  const [notesProductLeadsdd, setNotesProductLeadsdd] = useState('');
  const [lanoid, setlanoid]=useState('');
  const positions = [
    { value: "Hair & Make-Up", label: "Hair & Make-Up" },
    { value: "Art Direktion", label: "Art Direktion" },
    { value: "Fot", label: "Foto" },
    { value: "Foto Assist", label: "Foto Assist" },
    { value: "Video", label: "Video" },
    { value: "Digi-OP", label: "Digi-OP" },
    { value: "Styling", label: "Styling" },
    { value: "Styling Assist", label: "Styling Assist" },
    { value: "LOGISTIK / WARENAUFBEREITUNG", label: "LOGISTIK / WARENAUFBEREITUNG" },
    { value: "PRODUCER", label: "PRODUCER" },
    { value: "Art Buying", label: "Art Buying" }, 
    { value: "Account Management", label: "Account Management" }, 

  ];

  let userEmail=localStorage.getItem("userEmail");
  const [filterTalentPreferences, setfilterTalentPreferences] = useState([]);
  const [selectedPreferenceIndex, setSelectedPreferenceIndex] = useState(null); 
  useEffect(() => {
      const fetchPreferences = async () => {
          if (!userEmail) return;

          const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
          const q = query(usersRef, where("email", "==", userEmail));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
              const userData = querySnapshot.docs[0].data();
              const userPrefs = userData.filterTalentPreferences;
              if (userPrefs) {
                  setfilterTalentPreferences(userPrefs);
              }
          } else {
              console.log("No user or preferences found.");
          }
      };

      fetchPreferences();
  }, [userEmail]);

  const applyFilterPreference = (index) => {
      if (index < 0 || index >= filterTalentPreferences.length) {
          console.log("Invalid filter preference index.");
          return;
      }

      const preference = filterTalentPreferences[index];
      setfilterGoodCompanies(preference.filterGoodCompanies);
      setfilterTyoutCompanies(preference.filtertryoutCompanies);
      setfilterBadCompanies(preference.filterBadCompanies);
    setSelectedFunction(preference.selectedFunction);
      setfilterloation(preference.filterLocation);
      setSelectedType(preference.selectedType);
    

      // Apply other filters as needed
  };

  const preferenceOptions = filterTalentPreferences.map((_, index) => ({ value: index, label: `Preference ${index + 1}` }));
  const handlePreferenceChange = selectedOption => {
      setSelectedPreferenceIndex(selectedOption ? selectedOption.value : null);
      if (selectedOption) {
          applyFilterPreference(selectedOption.value);
      } else {
          // Clear all filter preferences here
          setfilterGoodCompanies(null);
      setfilterTyoutCompanies(null);
      setfilterBadCompanies(null);
      setSelectedFunction(null);
      setfilterloation(null);
      setSelectedType(null);
          // Reset any other filter states back to their initial values here
      }
  };
  

  const saveOrUpdateFilterPreference = async () => {
      const newPreference = {
        filterGoodCompanies,
        filtertryoutCompanies,
        filterBadCompanies,
        selectedFunction,
        filterLocation,
        selectedType
          // Add other filter settings as needed
      };
  
      if (selectedPreferenceIndex !== null) {
          // Update an existing preference
          filterTalentPreferences[selectedPreferenceIndex] = newPreference;
      } else {
          // Save a new preference if there's room
          if (filterTalentPreferences.length < 3) {
              filterTalentPreferences.push(newPreference);
          } else {
              alert('You can only save up to 3 filter preferences.');
              return;
          }
      }
  
      // Fetch the user's document reference again to ensure we have the correct document to update
      const usersRef = collection(db, `/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData`);
      const q = query(usersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
          const userDocRef = querySnapshot.docs[0].ref; // Document reference
          // Update the document with the new or updated preferences
          try {
              await updateDoc(userDocRef, { filterTalentPreferences: filterTalentPreferences });
              setfilterTalentPreferences([...filterTalentPreferences]); // Update local state
              alert('Filter preferences updated successfully.');
          } catch (error) {
              console.error('Error updating preferences:', error);
              alert('Failed to update filter preferences.');
          }
      } else {
          console.log("User document not found.");
      }
  };
  
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div style={{ width: "16px", margin: "0" }}>
            <svg
              version="1.1"
              id="Isolationsmodus"
              x="0px"
              y="0px"
              viewBox="0 0 322.9 257.78"
              shapeRendering="geometricPrecision"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M208.27,7.37c-4.69-4.79-11.12-7.4-17.72-7.81c-0.4-0.03-0.8-0.05-1.22-0.05l0,0h-35.34h-70.2H31.14h-4.27  C17.33-0.46,7.87,4.51,3.34,13.12C-1.19,21.65-1.3,32.3,4.19,40.47c0.85,1.3,1.74,2.56,2.6,3.82C17.3,59.73,27.77,75.2,38.27,90.64  c12.47,18.33,24.91,36.7,37.39,55.03c3.08,4.53,6.13,9.02,9.21,13.54c3.97,5.86,9.06,10.5,16.08,12.36  c6.87,1.86,14.85,0.93,20.9-2.93c3.79-2.45,6.24-5.12,8.8-8.79c8.06-11.54,15.82-23.3,23.76-34.96  c13.14-19.33,26.25-38.67,39.39-57.96c4.68-6.87,9.32-13.73,14-20.63c4.31-6.31,8.39-12.13,8.2-20.41  C215.85,18.95,213.1,12.34,208.27,7.37z"
              />
            </svg>
          </div>
        </components.DropdownIndicator>
      )
    );
  };
  const handlePostionchange = (selectedPosition) => {
    setselectedPosition(selectedPosition);
  };

  const customStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      border: "none",
    }),
    control: (base) => ({
      ...base,
      minWidth: "150px",
      borderColor: "black",
      borderWidth: "1px",
      boxShadow: "none",
      margin: "5px",
      borderRadius: "0px",
      color: "black",
      fontWeight: "700",
      height: "auto",
      textTransform: "uppercase",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontWeight: "700",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      fontWeight: "700",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "black" : "inherit",
      "&:hover": { backgroundColor: state.isSelected ? "black" : "#f7f7f7" },
    }),
  };

  const fetchCompanyNames = async () => {
    const companySnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/company")
    ); // Replace with the correct path
    const companyData = companySnap.docs.map((doc) => doc.data().name); // Assuming each document has a "name" field
    setCompanyNames(companyData);
  };

  const fetchlocations = async () => {
    const locationSnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations")
    );
    const locationData = locationSnap.docs.map((doc) => doc.data().short);
    setLocations(locationData);
  };

  const updateRelatedTalents = async () => {
    // Group talents by name
    let nameGroup = {};
    talents.forEach((talent) => {
      if (!nameGroup[talent.Name]) {
        nameGroup[talent.Name] = [];
      }
      nameGroup[talent.Name].push(talent.id);
    });

    // Update each talent with related IDs
    for (const [name, ids] of Object.entries(nameGroup)) {
      for (const id of ids) {
        let relatedIds = ids.filter((otherId) => otherId !== id);
        const talentRef = doc(
          db,
          "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
          id
        );
        await updateDoc(talentRef, { relatedTalent: relatedIds });
      }
    }

    alert("Related talents updated successfully!");
  };

  // Add a function to handle duplication of a talent
  const duplicateTalent = async (talent) => {
    setIsEditing(true);
    const newTalent = { ...talent };
    delete newTalent.id; // Remove original ID

    // Initialize relatedTalent array for new talent
    newTalent.relatedTalent = talent.relatedTalent
      ? [...talent.relatedTalent]
      : [];

    // Add the new talent to Firebase and get its ID
    const docRef = await addDoc(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data"),
      newTalent
    );
    const newTalentId = docRef.id;

    // Update the new talent to include the original talent's ID in its relatedTalent array
    newTalent.relatedTalent.push(talent.id);
    await updateDoc(docRef, {
      relatedTalent: newTalent.relatedTalent,
      Funktion: selectedPosition.label,
      Tagessatz: currentPrice,
      Notiz_Setcast: currentSurname,
      badPerformanceCompany: selectedBadCompanies,
      goodPerformanceCompany: selectedGoodCompanies,
      locations: selectLocations,
      Lano_ID:lanoid,
    });

    // Update the original talent with the new talent's ID
    const originalTalentRef = doc(
      db,
      "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
      talent.id
    );
    const updatedRelatedTalent = talent.relatedTalent
      ? [...talent.relatedTalent, newTalentId]
      : [newTalentId];
    await updateDoc(originalTalentRef, { relatedTalent: updatedRelatedTalent });
    setIsEditing(false);
    fetchTalents(); // Refresh the talents list
  };

  const fetchTalents = async () => {
    const talentSnap = await getDocs(
      collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data")
    );
    const talentData = talentSnap.docs.map((doc) => ({
      id: doc.id,
      locations: doc.data().locations || [] , // Ensure locations is an array
      ...doc.data(),
    }));
    setTalents(talentData);
  };
  useEffect(() => {
    fetchTalents();
    fetchCompanyNames();
    fetchlocations();
  }, []);
  const initiateDeleteTalent = (id) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to Delete this Talent?"
    );
    if (userConfirmed) {
      deleteTalent(id);
    }
  };
  const deleteTalent = async (id) => {
    await deleteDoc(
      doc(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data", id)
    );
    setIsEditing(false);
    window.confirm("Talent deleted sucsecfully");

    fetchTalents();
  };
  const set = [


    { value: 'On Model', label: 'On Model' },
    { value: 'On Produkt', label: 'On Produkt' },



  ];
  const editTalent = (talent) => {
    setIsEditing(true);
    setCurrentTalent(talent);
    setCurrentEmail(talent.E_Mail);
    setCurrentName(talent.Name);
    setCurrentFunction(talent.Funktion);
    setCurrentSurname(talent.Notiz_Setcast);
    setCurrentPhone(talent.Telefon);
    setCurrentOtherFunctions(talent.other_functions);
    setCurrentPrice(talent.Tagessatz);
    setlanoid(talent.Lano_ID);
    setBereich({ label: talent.Bereich, value: talent.Bereich });
    setselectedPosition({ label: talent.Funktion, value: talent.Funktion });
    setSelectedBadCompanies(
      talent.badPerformanceCompany ? talent.badPerformanceCompany : ""
    );
    setSelectedGoodCompanies(
      talent.goodPerformanceCompany ? talent.goodPerformanceCompany : ""
    );
    setSelectedtryoutCompanies(
      talent.tryOutCompany ? talent.tryOutCompany : ""
    );
    setSelectedLocations(talent.locations ? talent.locations : "");
    setNotesProductLeads(talent.notesProductLeads ? talent.notesProductLeads : '');
    setNotesProductLeadsdd(talent.notesProductLeadsdd ? talent.notesProductLeadsdd : '');
  };
  const updateTalentDetails = async () => {
    if (currentTalent) {
      const docRef = doc(
        db,
        "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data",
        currentTalent.id
      );

      // Add null checks and ensure arrays for all company types
      const goodCompaniesWithTimestamp = (selectedGoodCompanies || []).map(company => {
        const existingCompany = Array.isArray(currentTalent.goodPerformanceCompany) 
          ? currentTalent.goodPerformanceCompany.find(c => c.label === company.label)
          : null;
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      const badCompaniesWithTimestamp = (selectedBadCompanies || []).map(company => {
        const existingCompany = Array.isArray(currentTalent.badPerformanceCompany)
          ? currentTalent.badPerformanceCompany.find(c => c.label === company.label)
          : null;
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      const tryOutCompaniesWithTimestamp = (selectedtryoutCompanies || []).map(company => {
        const existingCompany = Array.isArray(currentTalent.tryOutCompany)
          ? currentTalent.tryOutCompany.find(c => c.label === company.label)
          : null;
        return existingCompany || {
          ...company,
          timestamp: new Date().toISOString()
        };
      });

      await updateDoc(docRef, {
        Name: currentName,
        Funktion: selectedPosition.label,
        E_Mail: currentEmail,
        Tagessatz: currentPrice,
        Notiz_Setcast: currentSurname,
        badPerformanceCompany: badCompaniesWithTimestamp,
        goodPerformanceCompany: goodCompaniesWithTimestamp,
        tryOutCompany: tryOutCompaniesWithTimestamp,
        locations: selectLocations,
        Telefon: currentPhone,
        Lano_ID: lanoid,
        Bereich: bereich.label,
      });

      fetchTalents();
      setIsEditing(false);
    }
  };
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };
  const handleFunctionChange = (e) => {
    setSelectedFunction(e.target.value);
  };
  const sortByName = () => {
    setTalents(
      [...talents].sort((a, b) => {
        return sortOrder
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      })
    );
    setSortOrder(!sortOrder); // Switch the sort order for the next click
  };

  const uniqueFunctions = [
    ...new Set(talents.map((talent) => talent.Funktion)),
  ];
  const functionOptions = uniqueFunctions.map((func) => ({
    value: func,
    label: func,
  }));
  const uniqueTypes = [...new Set(talents.map((talent) => talent.Bereich))];
  const typeOptions = uniqueTypes.map((type) => ({
    value: type,
    label: type,
  }));

  const companyOptions = companyNames
    .map((company) => ({
      value: company,
      label: company,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const locationOptions = locations
    .map((locations) => ({
      value: locations,
      label: locations,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const allTalentIds = talents.map(talent => talent.id);
    setExpandedRows(new Set(allTalentIds));
    setIsExpanded(true);
  }, [talents]);

  const toggleAllRows = () => {
    if (isExpanded) {
      setExpandedRows(new Set());
    } else {
      const allTalentIds = talents.map(talent => talent.id);
      setExpandedRows(new Set(allTalentIds));
    }
    setIsExpanded(!isExpanded);
  };

  // Add this function to handle individual row toggling
  const toggleRow = (id) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // Helper function to format date
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)}`;
  };

  // Update the display of companies in the list
  const CompanyWithDate = ({ company }) => {
    if (!company) return null;
    return (
      <span>
        {company.label}
        {company.timestamp ? ` (${formatDate(company.timestamp)})` : ''}
      </span>
    );
  };

  return (
    <section>
      <div className="content-wrapper">
        <h1>Talent List</h1>
 
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="inputtext search"
          />
          <div>
            <a
              className="big-button"
              href="/admin/add-company"
              style={{ textDecoration: "none", alignSelf: "start" }}
            >
              Add Company
            </a>
            <a
              className="big-button"
              href="/admin/addtalent"
              style={{ textDecoration: "none", alignSelf: "start" }}
            >
              Add Talent
            </a>
          </div>
        </div>

        <div
          style={{ display: "flex", marginTop: "10px" }}
          className="content-wrapper content-wrapper-talent-list"
        >
          <div style={{ marginRight: "10px" }}>
            <Select
              value={typeOptions.find(
                (option) => option.value === selectedType
              )}
              onChange={(option) => setSelectedType(option ? option.value : "")}
              options={typeOptions}
              isClearable
              placeholder="Select Type"
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              value={functionOptions.find(
                (option) => option.value === selectedFunction
              )}
              onChange={(option) =>
                setSelectedFunction(option ? option.value : "")
              }
              options={functionOptions}
              isClearable
              placeholder="Select Function"
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filterGoodCompanies}
              onChange={setfilterGoodCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Good "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filtertryoutCompanies}
              onChange={setfilterTyoutCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Try Out "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filterBadCompanies}
              onChange={setfilterBadCompanies}
              options={companyOptions}
              isClearable
              placeholder="Select Bad "
              styles={customStyles}
            />
          </div>
          <div>
            <Select
              isMulti
              value={filterLocation}
              onChange={setfilterloation}
              options={locationOptions}
              isClearable
              placeholder="Select Location "
              styles={customStyles}
            />
          </div>
          <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
            <button 
              className='button'
              onClick={toggleAllRows}
            >
              {isExpanded ? 'Collapse All' : 'Expand All'}
            </button>
          </div>
        </div>
      </div>
      <div className="content-wrapper content-wrapper-talent-list">
        <div className="talent-list">
          {isEditing ? (
            <></>
          ) : ( <>
            <div className="talent-item-header ">
              <div style={{ flexBasis: "300px" }}>Name</div>
              <div style={{ flexBasis: "150px" }}>Type</div>
              <div style={{ flexBasis: "200px" }}>Function</div>
              <div style={{ flexBasis: "350px" }}>Email</div>

              <div style={{ flexBasis: "250px" }}>Notes Setcast</div>
              <div style={{ flexBasis: "150px" }}>Day Rate</div>
              <div style={{ flexBasis: "200px" }}>Phone</div>
              <div style={{ flexBasis: "200px" }}>Good</div>
              <div style={{ flexBasis: "200px" }}>Try Out</div>
              <div style={{ flexBasis: "200px" }}>Bad</div>
              <div style={{ flexBasis: '300px' }} >Notes Product Leads</div>
              <div style={{ flexBasis: "50px " }}></div>
            </div>
                 <div style={{ height: "50px " }}></div>
                 </>
          )}
          {talents
            .sort((a, b) => {
              // Assuming 'Name' is the property you want to sort by
              const nameA = a.Name.toLowerCase(); // convert to lower case to ensure case-insensitive comparison
              const nameB = b.Name.toLowerCase(); // convert to lower case to ensure case-insensitive comparison
              if (nameA < nameB) return -1; // nameA comes first
              if (nameA > nameB) return 1; // nameB comes first
              return 0; // names are equal
            })
            .filter((talent) => {
              const searchLowerCase = search?.toLowerCase();
              return (
                (selectedType ? talent.Bereich === selectedType : true) &&
                (selectedFunction
                  ? talent.Funktion === selectedFunction
                  : true) &&
                (filterGoodCompanies &&
                Array.isArray(filterGoodCompanies) &&
                filterGoodCompanies.length > 0
                  ? talent.goodPerformanceCompany?.length > 0 &&
                    talent.goodPerformanceCompany.some((talentCompany) =>
                      filterGoodCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                (filterBadCompanies &&
                Array.isArray(filterBadCompanies) &&
                filterBadCompanies.length > 0
                  ? talent.badPerformanceCompany?.length > 0 &&
                    talent.badPerformanceCompany.some((talentCompany) =>
                      filterBadCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                (filtertryoutCompanies &&
                Array.isArray(filtertryoutCompanies) &&
                filtertryoutCompanies.length > 0
                  ? talent.tryOutCompany?.length > 0 &&
                    talent.tryOutCompany.some((talentCompany) =>
                      filtertryoutCompanies.some(
                        (selectedCompany) =>
                          selectedCompany.label === talentCompany.label
                      )
                    )
                  : true) &&
                  (filterLocation &&
                    Array.isArray(filterLocation) && filterLocation.length > 0
                      ? Array.isArray(talent.locations) && talent.locations.length > 0 &&
                        talent.locations.some(talentLocation =>
                          filterLocation.some(selectedLocation =>
                            selectedLocation.label === talentLocation.label
                          )
                        )
                      : true) &&
                (String(talent.Name)?.toLowerCase().includes(searchLowerCase) ||
                  String(talent.Name)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Funktion)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.E_Mail)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Gage)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Notiz_Setcast)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Tagessatz)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Telefon)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Verifizierung)
                    ?.toLowerCase()
                    .includes(searchLowerCase) ||
                  String(talent.Bereich)
                    ?.toLowerCase()
                    .includes(searchLowerCase))
              );
            })
            .map((talent) => (
              
              <div key={talent.id} className="talent-item">
                {isEditing && currentTalent.id === talent.id ? (
                  <>
                    {" "}
                    <span className="talent-item-input-title">Name</span>
                    <input
                      className="inputtext"
                      value={currentName}
                      onChange={(e) => setCurrentName(e.target.value)}
                    />
                    <span className="talent-item-input-title">Function</span>
                    <Select
                      components={{ DropdownIndicator }}
                      options={positions}
                      onChange={handlePostionchange}
                      placeholder="Function"
                      value={selectedPosition}
                      styles={customStyles}
                    />
                    <span className="talent-item-input-title">Locations</span>
                    <Select
                      isMulti
                      value={selectLocations}
                      onChange={setSelectedLocations}
                      options={locationOptions}
                      isClearable
                      placeholder="Locations"
                      styles={customStyles}
                    />
                    <span className="talent-item-input-title">Email</span>
                    <input
                      className="inputtext"
                      value={currentEmail}
                      onChange={(e) => setCurrentEmail(e.target.value)}
                    />
                    <span className="talent-item-input-title">
                      Notes SetCast
                    </span>
                    <input
                      className="inputtext"
                      value={currentSurname}
                      onChange={(e) => setCurrentSurname(e.target.value)}
                    />
                      <span className="talent-item-input-title">Lano ID</span>
                    <input
                      className="inputtext"
                      value={lanoid}
                      onChange={(e) => setlanoid(e.target.value)}
                    />
                    <span className="talent-item-input-title">Phone</span>
                    <input
                      className="inputtext"
                      value={currentPhone}
                      onChange={(e) => setCurrentPhone(e.target.value)}
                    />
                    <span className="talent-item-input-title">Rate in euro - only numbers</span>
                    <input
                      className="inputtext"
                      value={currentPrice}
                      onChange={(e) => {
                        // Use a regular expression to allow only numbers
                        const value = e.target.value;
                        const regex = /^[0-9]*$/;
                        
                        if (regex.test(value)) {
                          setCurrentPrice(value);
                        }
                      }}
                    />


    <Select
           
                      value={bereich}
                      onChange={setBereich}
                      options={set}
                      isClearable
                
                      styles={customStyles}
                    />

                    <span className="talent-item-input-title">
                      Good Performance Companies
                    </span>
                    <Select
                      isMulti
                      value={selectedGoodCompanies}
                      onChange={setSelectedGoodCompanies}
                      options={companyOptions}
                      isClearable
                      placeholder="Good Performance Companies"
                      styles={customStyles}
                    />
                    <span className="talent-item-input-title">
                      Bad Performance Companies
                    </span>
                    <Select
                      isMulti
                      value={selectedBadCompanies}
                      onChange={setSelectedBadCompanies}
                      options={companyOptions}
                      isClearable
                      placeholder="Bad Performance Companies"
                      styles={customStyles}
                    />
                    <span className="talent-item-input-title">
                      Try Out Companies
                    </span>
                    <Select
                      isMulti
                      value={selectedtryoutCompanies}
                      onChange={setSelectedtryoutCompanies}
                      options={companyOptions}
                      isClearable
                      placeholder="Try Out Companies"
                      styles={customStyles}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "30px",
                      }}
                    >
                      <div>
                        <button
                          className="button"
                          onClick={updateTalentDetails}
                          style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                          Save
                        </button>
                        <button
                          className="button"
                          onClick={() => setIsEditing(false)}
                          style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          className="button"
                          onClick={() => duplicateTalent(talent)}
                        >
                          Duplicate
                        </button>{" "}
                        <button
                          className="button-red"
                          onClick={() => initiateDeleteTalent(talent.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <div>
                      <p>
                        <i>
                          Notice: When you opt to duplicate, a new talent
                          profile will be created using the selected data, and
                          it will be associated with the current talent. Please
                          be aware that the original talent's profile will
                          remain unchanged.{" "}
                        </i>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div key={talent.id} className="talent-item-firstline">
                      <div style={{ flexBasis: "300px" }}>{talent.Name}</div>
                      <div style={{ flexBasis: "150px" }}>{talent.Bereich}</div>
                      <div style={{ flexBasis: "200px" }}>
                        {talent.Funktion}
                      </div>
                      <div style={{ flexBasis: "350px" }}>{talent.E_Mail}</div>
                      <div style={{ flexBasis: "250px" }}>
                        {expandedRows.has(talent.id) ? talent.Notiz_Setcast : 
                          (talent.Notiz_Setcast?.length > 60 ? 
                            `${talent.Notiz_Setcast.substring(0, 60)}...` : 
                            talent.Notiz_Setcast)}
                      </div>
                      <div style={{ flexBasis: "150px" }}>
                        {talent.Tagessatz}
                      </div>
                      <div style={{ flexBasis: "200px" }}>{talent.Telefon}</div>
                      <div style={{ flexBasis: "200px", color: "green" }}>
                        {expandedRows.has(talent.id) && talent.goodPerformanceCompany ? 
                          (Array.isArray(talent.goodPerformanceCompany) ? 
                            talent.goodPerformanceCompany.map((c) => (
                              <div key={c.label} style={{ 
                                marginBottom: '4px',
                                padding: '2px 4px',
                                backgroundColor: '#ffffff',
                                borderRadius: '3px',
                                fontSize: '0.9em',
                                border: '1px solid #e0e0e0',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                              }}>
                                {c.label}
                                {c.timestamp && (
                                  <div style={{ 
                                    color: '#666',
                                    fontSize: '0.85em',
                                    marginTop: '2px'
                                  }}>
                                    {formatDate(c.timestamp)}
                                  </div>
                                )}
                              </div>
                            )) : 
                            "") :
                          <TruncatedContent content={talent.goodPerformanceCompany} />
                        }
                      </div>
                      <div style={{ flexBasis: "200px" }}>
                        {expandedRows.has(talent.id) && talent.tryOutCompany ? 
                          (Array.isArray(talent.tryOutCompany) ? 
                            talent.tryOutCompany.map((c) => (
                              <div key={c.label} style={{ 
                                marginBottom: '4px',
                                padding: '2px 4px',
                                backgroundColor: '#ffffff',
                                borderRadius: '3px',
                                fontSize: '0.9em',
                                border: '1px solid #e0e0e0',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                              }}>
                                {c.label}
                                {c.timestamp && (
                                  <div style={{ 
                                    color: '#666',
                                    fontSize: '0.85em',
                                    marginTop: '2px'
                                  }}>
                                    {formatDate(c.timestamp)}
                                  </div>
                                )}
                              </div>
                            )) : 
                            "") :
                          <TruncatedContent content={talent.tryOutCompany} />
                        }
                      </div>
                      <div style={{ flexBasis: "200px", color: "red" }}>
                        {expandedRows.has(talent.id) && talent.badPerformanceCompany ? 
                          (Array.isArray(talent.badPerformanceCompany) ? 
                            talent.badPerformanceCompany.map((c) => (
                              <div key={c.label} style={{ 
                                marginBottom: '4px',
                                padding: '2px 4px',
                                backgroundColor: '#ffffff',
                                borderRadius: '3px',
                                fontSize: '0.9em',
                                border: '1px solid #e0e0e0',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                              }}>
                                {c.label}
                                {c.timestamp && (
                                  <div style={{ 
                                    color: '#666',
                                    fontSize: '0.85em',
                                    marginTop: '2px'
                                  }}>
                                    {formatDate(c.timestamp)}
                                  </div>
                                )}
                              </div>
                            )) : 
                            "") :
                          <TruncatedContent content={talent.badPerformanceCompany} />
                        }
                      </div>
                      <div style={{ flexBasis: '300px' }}>  
                        {expandedRows.has(talent.id) ? (
                          <>
                            {talent.notesProductLeads} - {talent.notesProductLeadsdd?.label}
                          </>
                        ) : (
                          <>
                            <TruncatedContent content={talent.notesProductLeads} type="notes" /> - 
                            <TruncatedContent content={talent.notesProductLeadsdd?.label} type="notes" />
                          </>
                        )}
                      </div>
                      <div style={{ flexBasis: "100px " }}>
                        <span style={{ marginRight: "20px" }}>
                          {selectedTalent === talent.id ? (
                            <button
                              className="edit-button"
                              onClick={() => setSelectedTalent(null)}
                            >
                              <img
                                src={closeButton}
                                alt="Close"
                                className="edit-container"
                              />
                            </button>
                          ) : (
                            <button
                              className="edit-button"
                              onClick={() => {
                                setSelectedTalent(talent.id);
                              }}
                            >
                              <img
                                src={calendarIcon}
                                alt="Calendar"
                                className="edit-container"
                              />
                            </button>
                          )}
                        </span>
                        <button
                          className="edit-button"
                          onClick={() => editTalent(talent)}
                        >
                          {" "}
                          <img
                            src={edit}
                            alt="Edit"
                            className="edit-container"
                          />
                        </button>
                        <button
                          className="toggle-button"
                          onClick={() => toggleRow(talent.id)}
                        >
                          <img
                            src={expanded}
                            alt={expandedRows.has(talent.id) ? "Collapse" : "Expand"}
                            className="edit-container"
                            style={{
                              transform: expandedRows.has(talent.id) ? 'rotate(180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s ease'
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {selectedTalent === talent.id && (
                  <div className="talent-item-firstline">
                    <div className="calendar">
                      <TalentCalendar
                        talentId={talent.id}
                        relatedTalents={talent.relatedTalent}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
export default TalentList;